import defaultAxios, { AxiosError } from "axios";
import type { FieldValues } from "react-hook-form";
import Swal from "sweetalert2";

type ErrorResponse = {
    code: number;
    message: string;
    data: any;
};

export default function handleErrorResponse<Values extends FieldValues>(
    error: unknown,
    thirdPartyCallback?: (data: any) => void,
) {
    if (defaultAxios.isAxiosError(error)) {
        const serverError = error as AxiosError<ErrorResponse | undefined>;
        if (serverError && serverError?.response) {
            const fieldError = serverError?.response?.data;

            if (!!fieldError?.message) {
                console.log("message", fieldError?.message);
                Swal.fire({
                    title: fieldError?.message,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else if (!!fieldError?.data?.errors?.message) {
                console.log("message", fieldError?.data.errors.message);
                Swal.fire({
                    title: fieldError?.data.errors.message,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else if (fieldError?.message === "third_party_error") {
                console.log("error", fieldError?.data);
                thirdPartyCallback && thirdPartyCallback(fieldError?.data);
            }

            console.log("error", fieldError);
        }

        console.log("serverError", serverError);
    }

    throw error;
}
