import React, { useState } from "react";
import { GetBroadcastLogContent } from "../../constants/types";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import handleErrorResponse from "../../utils/handleErrorResponse";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useFetching } from "../../context/fetchingContext";

interface IModalDeleteBroadcastProps {
    open: boolean;
    onClose: () => void;
    bcData: GetBroadcastLogContent;
}

const ModalDeleteBroadcast = ({
    open,
    onClose,
    bcData,
}: IModalDeleteBroadcastProps) => {
    const { setFetchingItems } = useFetching();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleDeleteBroadcast = async () => {
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("logId", String(bcData.id));
            const { data } = await axios.post(
                `/api/broadcast-hapus`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: data.message,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
                onClose();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => onClose()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Broadcast
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Broadcast kepada pelanggan dengan nama{" "}
                    {bcData.pelanggan.nama} dan nomor WhatsApp{" "}
                    {bcData.pelanggan.noTelp} akan dihapus secara permanen.
                    Yakin ingin menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => handleDeleteBroadcast()}
                    color="buttonred"
                >
                    Hapus
                </LoadingButton>
                <Button
                    variant="contained"
                    disableElevation
                    onClick={() => onClose()}
                    color="buttonred"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeleteBroadcast;
