import React, { useEffect, useMemo, useState } from "react";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import handleErrorResponse from "../../utils/handleErrorResponse";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import useBroadcastSetting from "../../services/queries/useBroadcastSetting";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";

interface IModalSettingBroadcastProps {
    open: boolean;
    onClose: () => void;
}

interface IValues {
    instanceId: string;
    aksesToken: string;
}

const ModalSettingBroadcast = ({
    open,
    onClose,
}: IModalSettingBroadcastProps) => {
    const { data: bcSetting } = useBroadcastSetting();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            instanceId: "",
            aksesToken: "",
        }),
        [],
    );

    const schema = yup.object({
        instanceId: yup.string().required("Kolom wajib diisi"),
        aksesToken: yup.string().required("Kolom wajib diisi"),
    });

    const { handleSubmit, control, reset } = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IValues) => {
        console.log(values);
        setIsButtonLoading(true);

        try {
            const formData = new FormData();
            formData.append("instanceId", values.instanceId);
            formData.append("aksesToken", values.aksesToken);

            const { data } = await axios.post(
                `/api/broadcast-setting`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: `${data.message}`,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                onClose();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
            console.error(error);
        }
    };

    useEffect(() => {
        if (open) {
            if (bcSetting) {
                reset({
                    instanceId: bcSetting.content.instanceId,
                    aksesToken: bcSetting.content.aksesToken,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [bcSetting, initialValues, open, reset]);

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => onClose()}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Pengaturan Broadcast
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack mt={2} spacing={2}>
                    <Controller
                        name="instanceId"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                label="Instance ID"
                                variant="outlined"
                                fullWidth
                                error={Boolean(error)}
                                helperText={error ? error.message : " "}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="aksesToken"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                label="Akses Token"
                                variant="outlined"
                                fullWidth
                                error={Boolean(error)}
                                helperText={error ? error.message : " "}
                                {...field}
                            />
                        )}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalSettingBroadcast;
