import * as React from "react";
// import Head from "next/head";
// import type { NextPage } from "next";
import { useFetching } from "../../context/fetchingContext";
import {
    GetBroadcastLogContent,
    ParamsGetBroadcastLog,
} from "../../constants/types";
import useBroadcastLog from "../../services/queries/useBroadcastLog";
import { GridColDef } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Add from "@mui/icons-material/Add";
import StyledDataGrid from "../../components/StyledDataGrid/StyledDataGrid";
import ModalBroadcast from "../../components/ModalBroadcast/ModalBroadcast";
import { green, red, yellow } from "@mui/material/colors";
import { Delete, Send } from "@mui/icons-material";
import ModalKonfirmasiBroadcast from "../../components/ModalKonfirmasiBroadcast/ModalKonfirmasiBroadcast";
import ModalSettingBroadcast from "../../components/ModalSettingBroadcast/ModalSettingBroadcast";
import SettingsIcon from "@mui/icons-material/Settings";
import ModalDeleteBroadcast from "../../components/ModalDeleteBroadcast/ModalDeleteBroadcast";
import Box from "@mui/material/Box";

const PengaturanBroadcast = () => {
    const theme = useTheme();
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isFetchingItems, cancelFetchingItems } = useFetching();
    const [isOpenModalBroadcast, setIsOpenModalBroadcast] =
        React.useState(false);
    const [isOpenModalBroadcastSatuan, setIsOpenModalBroadcastSatuan] =
        React.useState(false);
    const [isOpenModalSettingBroadcast, setIsOpenModalSettingBroadcast] =
        React.useState(false);
    const [isOpenModalDeleteBroadcast, setIsOpenModalDeleteBroadcast] =
        React.useState(false);
    // const [selectedLog, setSelectedLog] =
    //     React.useState<GetBroadcastLogContent>({
    //         id: 0,
    //         tanggal: "",
    //         pelanggan: {
    //             id: 0,
    //             nama: "",
    //             noTelp: "",
    //         },
    //         pesan: "",
    //         status: 0,
    //         statusPesan: "",
    //     });

    const [selectedLog, setSelectedLog] = React.useState<GetBroadcastLogContent>({
        id: 0,
        tanggal: "",
        pelanggan: { id: 0, nama: "", noTelp: "" },
        pesan: "",
        status: 0,
        statusPesan: "",
    });

    const openModalBroadcast = () => {
        setIsOpenModalBroadcast(true);
    };

    const closeModalBroadcast = () => {
        setIsOpenModalBroadcast(false);
    };

    const openModalBroadcastSatuan = (log: GetBroadcastLogContent) => {
        setSelectedLog(log);
        setIsOpenModalBroadcastSatuan(true);
    };

    const closeModalBroadcastSatuan = () => {
        setIsOpenModalBroadcastSatuan(false);
    };

    const openModalSettingBroadcast = () => {
        setIsOpenModalSettingBroadcast(true);
    };

    const closeModalSettingBroadcast = () => {
        setIsOpenModalSettingBroadcast(false);
    };

    const openModalDeleteBroadcast = (log: GetBroadcastLogContent) => {
        setSelectedLog(log);
        setIsOpenModalDeleteBroadcast(true);
    };

    const closeModalDeleteBroadcast = () => {
        setIsOpenModalDeleteBroadcast(false);
    };

    const initialParamsBroadcastLog = {
        size: 10,
        page: 1,
    };
    const [paramsBroadcastLog, setParamsBroadcastLog] =
        React.useState<ParamsGetBroadcastLog>(initialParamsBroadcastLog);

    const {
        data: broadcastLog,
        refetch: refetchBroadcastLog,
        isLoading: isLoadingBroadcastLog,
    } = useBroadcastLog(paramsBroadcastLog); 

    const handleOnPagination = (page: number) => {
        setParamsBroadcastLog((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsBroadcastLog((prev) => ({ ...prev, size }));
    };

    React.useEffect(() => {
        if (isFetchingItems === true) {
            refetchBroadcastLog();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchBroadcastLog]);

    const columns: GridColDef[] = [
        {
            field: "tanggal",
            headerName: "Tanggal",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value ?? "-"}>
                        <span className="table-cell-trucate">
                            {params.value ?? "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "pelanggan",
            headerName: "Pelanggan",
            flex: isLaptopScreen ? 0.75 : undefined,
            minWidth: 250,
            // renderCell: (params) => {
            //     return (
            //         <Tooltip title={params.value.nama ?? "-"}>
            //             <span className="table-cell-trucate">
            //                 {params.value.nama ?? "-"}
            //             </span>
            //         </Tooltip>
            //     );
            // },

            renderCell: (params) => {
                return (
                    <Tooltip title={params?.value?.nama ?? "-"}>
                        <span className="table-cell-trucate">
                            {params?.value?.nama ?? "-"}
                        </span>
                    </Tooltip>
                );
            },
            
        },
        {
            field: "pesan",
            headerName: "Pesan",
            flex: isLaptopScreen ? 2 : undefined,
            minWidth: 250,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value ?? "-"}>
                        <span className="table-cell-trucate">
                            {params.value ?? "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "statusPesan",
            headerName: "Status",
            flex: isLaptopScreen ? 0.5 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value ?? "Gagal"}>
                        <Typography
                            variant="body2"
                            color={
                                params.value === "Menunggu diproses!"
                                    ? yellow[800]
                                    : params.value === "Broadcast Berhasil"
                                    ? green[500]
                                    : red[500]
                            }
                        >
                            {params.value ?? "Gagal"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            align: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const row: GetBroadcastLogContent = params.row;
                return (
                    <Stack direction="row" spacing={2}>
                        <Tooltip
                            title={
                                row.status === 1
                                    ? "Pesan telah dibroadcast"
                                    : "Broadcast pesan"
                            }
                        >
                            <span className="table-cell-trucate">
                                <Button
                                    disabled={row.status === 1}
                                    variant="contained"
                                    onClick={() =>
                                        openModalBroadcastSatuan(row)
                                    }
                                    size="small"
                                    color="buttongreen"
                                    disableElevation
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                >
                                    <Send
                                        sx={{ width: "16px", height: "16px" }}
                                    />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Hapus Broadcast">
                            <span className="table-cell-trucate">
                                <Button
                                    disabled={
                                        row.statusPesan !== "Broadcast Gagal" &&
                                        row.status === 1
                                    }
                                    variant="contained"
                                    onClick={() =>
                                        openModalDeleteBroadcast(row)
                                    }
                                    size="small"
                                    color="buttonred"
                                    disableElevation
                                    sx={{
                                        minWidth: "unset",
                                        padding: "8px",
                                        width: "32px",
                                        height: "32px",
                                    }}
                                >
                                    <Delete
                                        sx={{ width: "16px", height: "16px" }}
                                    />
                                </Button>
                            </span>
                        </Tooltip>
                    </Stack>
                );
            },
        },
    ];

    return (
        <Box>
            <title>Pengaturan Broadcast WA :: ULO Juragan</title>
            <Stack spacing={6} paddingY={3}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} md={7} lg={9}>
                        <Typography variant="h6" fontWeight="bold">
                            Pengaturan Broadcast WA
                        </Typography>
                    </Grid>
                </Grid>
                <Stack spacing={3} sx={{marginTop: "0 !important",}}>
                    <Stack
                        direction={isLaptopScreen ? "row" : "column"}
                        spacing={2}
                        justifyContent={"flex-end"}
                    >
                        <Button
                            variant="outlined"
                            startIcon={<SettingsIcon />}
                            disableElevation
                            fullWidth={!isLaptopScreen}
                            onClick={() => openModalSettingBroadcast()}
                        >
                            Pengaturan Broadcast
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<Add />}
                            disableElevation
                            fullWidth={!isLaptopScreen}
                            onClick={() => openModalBroadcast()}
                        >
                            Buat Antrian Broadcast
                        </Button>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="body2">
                            Broadcast akan dikirim secara otomatis setiap 1
                            jam untuk 5 broadcast agar tidak dianggap spam
                            oleh WhatsApp.
                        </Typography>
                        <StyledDataGrid
                            height={640}
                            rows={broadcastLog?.content ?? []}
                            columns={columns}
                            handleOnSizeChange={handleOnSizeChange}
                            handleOnPagination={handleOnPagination}
                            rowCount={broadcastLog?.totalElements ?? 0}
                            page={paramsBroadcastLog.page - 1}
                            pageSize={paramsBroadcastLog.size}
                            loading={isLoadingBroadcastLog}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <ModalBroadcast
                isOpenModalBroadcast={isOpenModalBroadcast}
                closeModalBroadcast={closeModalBroadcast}
            />
            <ModalKonfirmasiBroadcast
                open={isOpenModalBroadcastSatuan}
                onClose={closeModalBroadcastSatuan}
                bcData={selectedLog}
            />
            <ModalSettingBroadcast
                open={isOpenModalSettingBroadcast}
                onClose={closeModalSettingBroadcast}
            />
            <ModalDeleteBroadcast
                open={isOpenModalDeleteBroadcast}
                onClose={closeModalDeleteBroadcast}
                bcData={selectedLog}
            />
        </Box>
    );
};

export default PengaturanBroadcast;
