import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useMemo, useState } from "react";
import usePelanggan from "../../services/queries/usePelanggan";
import { useFetching } from "../../context/fetchingContext";
import defaultAxios, { AxiosError } from "axios";
import axios from "../../services/axios";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    GetPelangganContent,
    IOptions,
    ParamsGetPelanggan,
} from "../../constants/types";
import Swal from "sweetalert2";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBox from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import useUser from "../../services/queries/useUser";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Divider from "@mui/material/Divider";
import handleErrorResponse from "../../utils/handleErrorResponse";
import { useAuth } from "../../context/authContext";

interface IModalBroadcastProps {
    isOpenModalBroadcast: boolean;
    closeModalBroadcast: () => void;
}

interface IValues {
    pelangganId: GetPelangganContent[];
    pesan: string;
}

const ModalBroadcast = ({
    isOpenModalBroadcast,
    closeModalBroadcast,
}: IModalBroadcastProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { data: user } = useUser();
    const { ukmIdUser } = useAuth();

    const initialParamsPelanggan = {
        ukmId: undefined,
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsPelanggan, setParamsPelanggan] =
        React.useState<ParamsGetPelanggan>(initialParamsPelanggan);
    const { data: pelanggan, isLoading: isLoadingPelanggan } =
        usePelanggan(paramsPelanggan);
    const { setFetchingItems } = useFetching();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    // const mappedPelanggan = pelanggan?.content.map((li) => ({
    //     id: li.id,
    //     nama: li.nama,
    // }));

    const initialValues = useMemo(
        () => ({
            pelangganId: [],
            pesan: "",
        }),
        [],
    );

    const schema = yup.object({
        pelangganId: yup
            .array()
            .min(1, "Pilih pelanggan minimal 1")
            .of(
                yup.object().shape({
                    id: yup.string().required("Kolom wajib diisi"),
                    nama: yup.string().required("Kolom wajib diisi"),
                }),
            )
            .required("Kolom wajib diisi"),
        pesan: yup.string().required("Kolom wajib diisi"),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors,
        reset,
        watch,
    } = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const pelangganId = watch("pelangganId");

    useEffect(() => console.log("pelangganId", pelangganId), [pelangganId]);

    const onSubmit = async (values: IValues) => {
        console.log(values);
        setIsButtonLoading(true);

        try {
            const body = {
                pelanggan: values.pelangganId.map((li) => Number(li.id)),
                pesan: values.pesan,
            };

            const { data } = await axios.post(`/api/broadcast-antrian`, body);
            if (data.code === 200) {
                Swal.fire({
                    title: `${data.message}`,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
                closeModalBroadcast();
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (user) {
            setParamsPelanggan((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [user]);

    React.useEffect(() => {
        if (isOpenModalBroadcast) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalBroadcast, reset]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth
            open={isOpenModalBroadcast}
            onClose={() => closeModalBroadcast()}
            scroll="body"
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => closeModalBroadcast()}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Buat Antrian Broadcast
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent
                dividers
                sx={{ padding: isPhoneScreen ? undefined : 6 }}
            >
                <Grid
                    container
                    alignItems="center"
                    rowSpacing={2}
                    columnSpacing={2}
                >
                    <Grid item xs={12} sm={12}>
                        <Controller
                            control={control}
                            name="pelangganId"
                            render={({
                                field: { onChange, value },
                                fieldState,
                            }) => (
                                <Autocomplete
                                    multiple
                                    options={pelanggan?.content ?? []}
                                    fullWidth
                                    disableCloseOnSelect
                                    disablePortal
                                    freeSolo={false}
                                    loading={isLoadingPelanggan}
                                    value={value}
                                    onChange={(
                                        _event,
                                        newToko: GetPelangganContent[],
                                        reason,
                                    ) => {
                                        if (
                                            reason === "clear" ||
                                            reason === "removeOption"
                                        ) {
                                            setSelectAll(false);
                                        }
                                        if (
                                            reason === "selectOption" &&
                                            newToko.length ===
                                                pelanggan?.content?.length
                                        ) {
                                            console.log("isit");
                                            setSelectAll(true);
                                        }
                                        onChange(newToko);
                                    }}
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={
                                                    <CheckBoxOutlineBlank fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBox fontSize="small" />
                                                }
                                                style={{
                                                    marginRight: 8,
                                                }}
                                                checked={selected}
                                            />
                                            {option.nama} ({option.noTelp})
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Pilih pelanggan"
                                            error={Boolean(errors.pelangganId)}
                                            helperText={
                                                fieldState.error?.message ?? ""
                                            }
                                        />
                                    )}
                                    PaperComponent={(paperProps) => {
                                        const { children, ...restPaperProps } =
                                            paperProps;
                                        return (
                                            <Paper {...restPaperProps}>
                                                <Box
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    } // prevent blur
                                                    pl={3.25}
                                                    py={0.5}
                                                >
                                                    <FormControlLabel
                                                        onClick={(e) => {
                                                            e.preventDefault(); // prevent blur
                                                            if (
                                                                pelanggan?.content
                                                            ) {
                                                                setSelectAll(
                                                                    (prev) => {
                                                                        if (
                                                                            !prev
                                                                        )
                                                                            setValue(
                                                                                "pelangganId",
                                                                                [
                                                                                    ...pelanggan?.content,
                                                                                ],
                                                                            );
                                                                        else
                                                                            setValue(
                                                                                "pelangganId",
                                                                                [],
                                                                            );
                                                                        return !prev;
                                                                    },
                                                                );
                                                            }
                                                        }}
                                                        label="Pilih Semua"
                                                        control={
                                                            <Checkbox
                                                                checked={
                                                                    selectAll
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    marginRight: 1.5,
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Box>
                                                <Divider />
                                                {children}
                                            </Paper>
                                        );
                                    }}
                                    getOptionLabel={(option) =>
                                        `${option.nama}`
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        String(option.id) === String(value.id)
                                    }
                                    ListboxProps={{
                                        style: {
                                            maxHeight: "190px",
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="pesan"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    label="Pesan"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    error={Boolean(errors.pesan)}
                                    helperText={
                                        errors.pesan
                                            ? errors.pesan.message
                                            : " "
                                    }
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    marginBottom={3}
                >
                    <LoadingButton
                        disableElevation
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Buat
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalBroadcast;
