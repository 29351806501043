import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid, GridColumns, GridRowIdGetter } from "@mui/x-data-grid";
import React from "react";
import AscDescIcon from "../AscDescIcon/AscDescIcon";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import NoRowsImage from "../NoRowsImage/NoRowsImage";

interface IStyledDataGridProps {
    rows: readonly any[];
    columns: GridColumns;
    handleOnSizeChange: (size: number) => void;
    handleOnPagination: (page: number) => void;
    rowCount?: number;
    page?: number;
    pageSize?: number;
    loading?: boolean;
    getRowId?: GridRowIdGetter;
    height: number;
}

const StyledDataGrid = ({
    rows,
    columns,
    handleOnSizeChange,
    handleOnPagination,
    rowCount,
    page,
    pageSize,
    loading,
    getRowId,
    height,
}: IStyledDataGridProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            sx={{
                height: isPhoneScreen ? undefined : height,
                width: "100%",
                "& .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                },
                "& .MuiDataGrid-iconButtonContainer": {
                    visibility: "visible",
                    width: "0 !important",
                },
                "& .MuiDataGrid-cell": {
                    maxHeight: "fit-content !important",
                    overflow: "auto",
                    whiteSpace: "initial !important",
                    lineHeight: "16px !important",
                    display: "flex !important",
                    alignItems: "center",
                    paddingTop: "10px !important",
                    paddingBottom: "10px !important",
                },
                "& .MuiDataGrid-viewport,.MuiDataGrid-row,.MuiDataGrid-renderingZone":
                    {
                        maxHeight: "fit-content !important",
                    },
            }}
        >
            <DataGrid
                autoHeight={isPhoneScreen}
                paginationMode="server"
                components={{
                    ColumnUnsortedIcon: AscDescIcon,
                    NoRowsOverlay: NoRowsImage,
                    LoadingOverlay: LoadingTabel,
                }}
                getRowId={getRowId}
                rows={rows}
                columns={columns}
                disableColumnMenu
                disableSelectionOnClick
                rowsPerPageOptions={[10]}
                onPageSizeChange={handleOnSizeChange}
                onPageChange={handleOnPagination}
                rowCount={rowCount}
                page={page}
                pageSize={pageSize}
                density="standard"
                loading={loading}
            />
        </Box>
    );
};

export default StyledDataGrid;
