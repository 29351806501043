import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
// import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { BlobProvider } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Stack,
    Typography,
    IconButton,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Button,
    Autocomplete,
    TextField,
    Chip,
    Link,
    InputAdornment,
    Tooltip,
    FormHelperText,
    FormControl,
} from "@mui/material";
import {
    GetPelangganContent,
    IFaktur,
    // InputPembayaranBody,
    InputPembayaranResponse,
    IOptions,
    ModalPelangganField,
    ParamsGetPelanggan,
    // ParamsGetItem,
    ParamsGetUkm,
} from "../../constants/types";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import { ArrowDropDown, Print } from "@mui/icons-material";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import { IconContext } from "react-icons";
import { GiMoneyStack, GiWallet } from "react-icons/gi";
import moment from "moment";
import truncate from "../../utils/truncate";
import { Controller, useForm } from "react-hook-form";
import usePelanggan from "../../services/queries/usePelanggan";
import ReceiptIcon from "@mui/icons-material/Receipt";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import PrintDapur from "../PrintStruk/PrintDapur";
import { saveToStorage } from "../../utils/saveFromStorage";
import { DISPLAY_INFO, JURAGAN_URL } from "../../constants/common";
import toRupiah from "../../utils/toRupiah";
// import printJS from "print-js-updated";

registerLocale("id", id);
setDefaultLocale("id");

interface IModalKonfirmasiBayarProps {
    refetch: () => void;
    persentaseDiskon?: number | null;
    diskon?: number;
    persentasePajak?: number | null;
    pajak?: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: IOptions | null;
    keteranganBeli: string;
    keteranganBeli2: string;
    resetKeteranganBeli: () => void;
}

enum ModePembayaran {
    TUNAI = "tunai",
    TRANSFER = "transfer",
    UTANG = "utang",
}

type ToggleKirimStruk = "email" | "whatsapp";

export const ModalKonfirmasiBayar = (props: IModalKonfirmasiBayarProps) => {
    const theme = useTheme();
    const pDapur = React.useRef<any>(null);
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    // const initialParams = {
    //     ukmId: ukmIdUser && ukmIdUser[0],
    //     size: 500,
    //     page: 1,
    //     search: "",
    //     kategori: "",
    // };

    const [printStruk, setPrintStruk] = useState(false);
    const [printDapur, setPrintDapur] = useState(false);
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    // const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    // const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [toggleModePembayaran, setToggleModePembayaran] = useState(
        props.kembalian < 0 ? ModePembayaran.UTANG : ModePembayaran.TUNAI,
    );
    const [selectedMetode, setSelectedMetode] = useState<{
        id: number;
        ukmId: {
            id: number | null;
            nama: string | null;
        };
        namaMetode: string;
        charge: {
            mode: string;
            charge: number;
        };
        noTujuan: string | null;
        namaPemegang: string | null;
        gambar: string | null;
    } | null>(null);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [toggleKirimStruk, setToggleKirimStruk] =
        useState<ToggleKirimStruk>("whatsapp");
    const pilihWhatsapp = () => {
        setToggleKirimStruk("whatsapp");
        setEmail("");
    };

    const pilihEmail = () => {
        setToggleKirimStruk("email");
        setEmail("");
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { data: metodePembayaran, isLoading: isLoadingMetodePembayaran } =
        useMetodePembayaran(ukmId);

    // const { data: findMetodePembayaran } = useFindMetodePembayaran(
    //     selectedMetode?.id,
    // );

    // const mappedMetodePembayaran = metodePembayaran?.map((li) => ({
    //     id: li.id,
    //     nama: li.namaMetode,
    // }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));

    const initialParamsPelanggan = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsPelanggan, setParamsPelanggan] =
        React.useState<ParamsGetPelanggan>(initialParamsPelanggan);

    const { data: pelanggan, isLoading: isLoadingPelanggan } =
        usePelanggan(paramsPelanggan);

    const pilihTunai = () => {
        setToggleModePembayaran(ModePembayaran.TUNAI);
    };

    const pilihTransfer = () => {
        setToggleModePembayaran(ModePembayaran.TRANSFER);
    };

    const pilihUtang = () => {
        setToggleModePembayaran(ModePembayaran.UTANG);
    };
    const {
        cart,
        totalHarga,
        isOpenModalKonfirmasiBayar,
        closeModalBayar,
        closeModalKonfirmasiBayar,
        // resetCart,
        handleReset,
        // isPrintStruk,
        // resetPrint,
        setFetchingItems,
        handlingDonePembayaran,
        // diskon,
        // grandTotal,
        // bayar,
        // kembalian,
        checked,
        setChecked,
        // keteranganBeli,
        handleIsKeterangan,
        // resetKeteranganBeli,
    } = useTransaksi();

    const noInvoice = useMemo(
        () => `LCM-${moment().format("YYMMDDHHmm")}`,
        [isOpenModalKonfirmasiBayar],
    );

    useEffect(() => console.log("noInvoice", noInvoice), [noInvoice]);

    const current = new Date();
    current.getSeconds();

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (cart) {
            cart.forEach((list) => {
                total += list.harga * (list.qty ?? 0);
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [cart]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [cart, handleHitungTotalHargaSeluruhItem]);

    const handlePrintStruk = (checked: boolean) => {
        if (checked) {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    };

    // const handlePrintDapur = (checked: boolean) => {
    //     if (checked) {
    //         setPrintDapur(true);
    //     } else {
    //         setPrintDapur(false);
    //     }
    // };

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("printStruk") === "true") {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem("printDapur") === "true") {
            setPrintDapur(true);
        } else {
            setPrintDapur(false);
        }
    }, []);

    useEffect(() => {
        if (props.kembalian < 0) {
            pilihUtang();
        } else {
            pilihTunai();
        }
    }, [props.kembalian]);

    useEffect(() => {
        if (
            props.diskon ||
            props.kembalian ||
            props.pajak ||
            props.persentaseDiskon ||
            props.persentasePajak ||
            props.totalTagihan ||
            props.uangDibayar
        ) {
            console.log("jalan");
            saveToStorage(
                DISPLAY_INFO,
                JSON.stringify({
                    diskon: props.diskon,
                    kembalian: props.kembalian,
                    pajak: props.pajak,
                    persentaseDiskon: props.persentaseDiskon,
                    persentasePajak: props.persentasePajak,
                    totalTagihan: props.totalTagihan,
                    uangDibayar: props.uangDibayar,
                }),
            );
        }
    }, [
        isOpenModalKonfirmasiBayar,
        props.diskon,
        props.kembalian,
        props.pajak,
        props.persentaseDiskon,
        props.persentasePajak,
        props.totalTagihan,
        props.uangDibayar,
    ]);

    const filterNewKodeFaktur = (idBundling: number) => {
        let kodeFakturBundling: IFaktur[] = [];
        cart.forEach((li) =>
            li.detailBundling
                .filter((fd) => fd.bundlingId === idBundling)
                .forEach((db) => {
                    if (db.newFaktur) {
                        db.newFaktur.forEach((nf) => {
                            kodeFakturBundling.push(nf);
                        });
                    }
                }),
        );
        return kodeFakturBundling.map((li) => ({
            idFaktur: li.fakturId,
            kodeFaktur: li.kodeFaktur,
            jumlahItem: li.jumlahItem,
        }));
    };

    const initialValues: ModalPelangganField = useMemo(
        () => ({
            isPelangganBaru: false,
            nama: "",
            alamat: "",
            noTelepon: "",
            tglLahir: null,
            jatuhTempo: null,
        }),
        [],
    );

    const { handleSubmit, control, reset, watch } =
        useForm<ModalPelangganField>({
            defaultValues: initialValues,
            mode: "all",
        });

    const watchPelanggan = watch("pelanggan");
    const watchIsPelangganBaru = watch("isPelangganBaru");
    const watchJatuhTempo = watch("jatuhTempo");

    const onSubmit = async (values: ModalPelangganField) => {
        setIsButtonLoading(true);
        const formattedNoTelp = "62" + values.noTelepon;
        const body = {
            ukmId: ukmIdUser && ukmIdUser[0],
            noInvoice: noInvoice,
            jenisItem: cart.map((li) => ({
                itemId: li.id,
                namaItem: li.namaBarang,
                skuItem: li.sku,
                jumlahItem: li.qty,
                hargaSatuan: li.harga,
                hargaTotal: li.harga * li.qty,
                kodeFaktur: checked
                    ? li.detailBundling.length > 0
                        ? filterNewKodeFaktur(li.id)
                        : li.faktur.map((fa) => ({
                              idFaktur: fa.fakturId,
                              kodeFaktur: fa.kodeFaktur,
                              jumlahItem: fa.jumlahItem,
                          }))
                    : null,
            })),
            userInput: `${user?.userId}`,
            sales: !!props.sales ? props.sales.id : null,
            totalHarga: totalHargaSeluruhItem,
            diskon: props.diskon,
            pajak: props.pajak,
            totalPembayaran: props.totalTagihan,
            uangYangDibayar: props.uangDibayar,
            kembalian: props.kembalian,
            metodeId:
                toggleModePembayaran === ModePembayaran.TUNAI ||
                toggleModePembayaran === ModePembayaran.UTANG
                    ? 0
                    : selectedMetode?.id,
            email: toggleKirimStruk === "email" ? email : null,
            keterangan: !!props.keteranganBeli ? props.keteranganBeli : null,
            keteranganLain: !!props.keteranganBeli2
                ? props.keteranganBeli2
                : null,
            pelanggan: values.isPelangganBaru
                ? undefined
                : values.pelanggan
                ? values.pelanggan.id
                : null,
            pelangganBaru: values.isPelangganBaru
                ? {
                      nama: values.nama,
                      alamat: values.alamat,
                      noTelepon: formattedNoTelp,
                      tglLahir: moment(values.tglLahir).format("YYYY-MM-DD"),
                  }
                : undefined,
            isPiutang: props.kembalian < 0 ? 1 : 0,
            dp: props.kembalian < 0 ? props.uangDibayar : undefined,

            jatuhTempo: values.jatuhTempo
                ? moment(values.jatuhTempo).format("YYYY-MM-DD")
                : undefined,
        };
        // console.log("body", body);
        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                body,
            );
            if (data.code === 200) {
                if (kirimEmail && toggleKirimStruk === "whatsapp") {
                    window.open(
                        `https://api.whatsapp.com/send/?phone=62${email}&text=Struk%20pembelian%20di%20LCM%20%28Lunpia%20Cik%20Me%20Me%29%20total%20${toRupiah(
                            props.totalTagihan,
                        )}%0A%0ALihat%20detail%20di%20${
                            window.location.origin
                        }/struk/${data.data.encrypt}`,
                        "_blank",
                    );
                }
                closeModalKonfirmasiBayar();
                closeModalBayar();
                Swal.fire({
                    title: "Transaksi berhasil!",
                    text: "Pembayaran Anda telah berhasil",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                if (printStruk) {
                    localStorage.setItem("printStruk", "true");
                } else {
                    localStorage.removeItem("printStruk");
                }
                if (printDapur) {
                    localStorage.setItem("printDapur", "true");
                } else {
                    localStorage.removeItem("printDapur");
                }
                setFetchingItems();
                handleReset();
                setChecked(false);
                setIsButtonLoading(false);
                handleIsKeterangan(false);
                props.resetKeteranganBeli();
                saveToStorage(
                    DISPLAY_INFO,
                    JSON.stringify({
                        diskon: 0,
                        kembalian: 0,
                        pajak: 0,
                        persentaseDiskon: 0,
                        persentasePajak: 0,
                        totalTagihan: 0,
                        uangDibayar: 0,
                    }),
                );
                handlingDonePembayaran();
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                } else {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                }
            }
        },
        [closeModalBayar, closeModalKonfirmasiBayar],
    );

    React.useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    useEffect(() => {
        if (isOpenModalKonfirmasiBayar) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalKonfirmasiBayar, reset]);

    return (
        <Box position="relative">
            <Dialog
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen || isTabletScreen}
                open={isOpenModalKonfirmasiBayar}
                onClose={() => {
                    closeModalKonfirmasiBayar();
                }}
                scroll="paper"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={closeModalKonfirmasiBayar}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Konfirmasi Pembayaran
                        </Typography>
                    </Stack>
                </DialogTitle>
                {/* <Divider flexItem /> */}
                <DialogContent
                    sx={{
                        paddingLeft: 0,
                        paddingTop: 0,
                        paddingRight: 0,
                        paddingBottom: fullScreen ? "250px" : 0,
                    }}
                >
                    <Stack direction={{ xs: "column-reverse", md: "row" }}>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            paddingX={3}
                            paddingBottom={4}
                            height={
                                fullScreen
                                    ? undefined
                                    : isTabletScreen
                                    ? "calc(100vh - 72px)"
                                    : "calc(90vh - 72px)"
                            }
                            sx={{
                                overflowY: fullScreen ? undefined : "auto",
                            }}
                        >
                            <Stack
                                justifyContent={"space-between"}
                                sx={{ height: "100%" }}
                            >
                                <Box>
                                    <Box
                                        padding={2}
                                        border={1}
                                        borderColor="#d4d4d4"
                                        borderRadius={2}
                                        marginBottom={2}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            color="#000000"
                                            fontWeight="bold"
                                            marginBottom={2}
                                        >
                                            Metode Pembayaran
                                        </Typography>
                                        <Grid container spacing={2}>
                                            {props.kembalian < 0 ? (
                                                <Grid item xs={12}>
                                                    <Button
                                                        fullWidth
                                                        variant={
                                                            toggleModePembayaran ===
                                                            ModePembayaran.UTANG
                                                                ? "contained"
                                                                : "outlined"
                                                        }
                                                        onClick={() => {
                                                            console.log(
                                                                "pressed",
                                                            );
                                                        }}
                                                        sx={{ borderRadius: 4 }}
                                                    >
                                                        <Stack
                                                            flexDirection={
                                                                "row"
                                                            }
                                                            alignItems="center"
                                                        >
                                                            {/* <IconContext.Provider
                                                value={{
                                                    size: "56px",
                                                }}
                                            >
                                                <GiMoneyStack />
                                            </IconContext.Provider> */}
                                                            <ReceiptIcon
                                                                sx={{
                                                                    width: 56,
                                                                    height: 56,
                                                                }}
                                                            />
                                                            <Typography
                                                                fontSize={
                                                                    isTabletScreen
                                                                        ? 10
                                                                        : 12
                                                                }
                                                            >
                                                                Piutang
                                                            </Typography>
                                                        </Stack>
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <React.Fragment>
                                                    <Grid item xs={12} md={6}>
                                                        <Button
                                                            fullWidth
                                                            variant={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.TUNAI
                                                                    ? "contained"
                                                                    : "outlined"
                                                            }
                                                            onClick={pilihTunai}
                                                            sx={{
                                                                borderRadius: 4,
                                                            }}
                                                        >
                                                            <Stack alignItems="center">
                                                                <IconContext.Provider
                                                                    value={{
                                                                        size: "56px",
                                                                    }}
                                                                >
                                                                    <GiMoneyStack />
                                                                </IconContext.Provider>
                                                                Tunai
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Stack
                                                            component={
                                                                ukmData?.isPro ===
                                                                0
                                                                    ? Link
                                                                    : Stack
                                                            }
                                                            underline="none"
                                                            onClick={() => {
                                                                if (
                                                                    ukmData?.isPro ===
                                                                    1
                                                                ) {
                                                                    return;
                                                                } else {
                                                                    setIsOpenModalAlertPro(
                                                                        true,
                                                                    );
                                                                }
                                                            }}
                                                            sx={{
                                                                cursor:
                                                                    ukmData?.isPro ===
                                                                    0
                                                                        ? "pointer"
                                                                        : undefined,
                                                            }}
                                                        >
                                                            <Button
                                                                // disabled={
                                                                //     ukmData?.isPro !== 1 ||
                                                                //     metodePembayaran?.length === 0
                                                                // }
                                                                fullWidth
                                                                variant={
                                                                    toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        ukmData?.isPro ===
                                                                        1
                                                                    ) {
                                                                        pilihTransfer();
                                                                    }
                                                                }}
                                                                sx={{
                                                                    borderRadius: 4,
                                                                }}
                                                            >
                                                                <Stack alignItems="center">
                                                                    <IconContext.Provider
                                                                        value={{
                                                                            size: "56px",
                                                                        }}
                                                                    >
                                                                        <GiWallet />
                                                                    </IconContext.Provider>
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        gap={1}
                                                                    >
                                                                        Metode
                                                                        lain{" "}
                                                                        {ukmData?.isPro ===
                                                                        1 ? null : (
                                                                            <Chip
                                                                                label="PRO"
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Stack>
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                    </Box>
                                    <Grid container spacing={2} mb={2}>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="isPelangganBaru"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                size={
                                                                    isTabletScreen
                                                                        ? "small"
                                                                        : undefined
                                                                }
                                                                {...field}
                                                            />
                                                        }
                                                        label="Pelanggan baru"
                                                        componentsProps={{
                                                            typography: {
                                                                variant:
                                                                    "body2",
                                                                fontSize:
                                                                    isTabletScreen
                                                                        ? "12px"
                                                                        : undefined,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: watchIsPelangganBaru
                                                    ? "none"
                                                    : undefined,
                                            }}
                                        >
                                            <Typography>
                                                Pilih pelanggan{" "}
                                                {toggleModePembayaran ===
                                                ModePembayaran.UTANG
                                                    ? ""
                                                    : "(opsional)"}
                                            </Typography>
                                            <Controller
                                                name="pelanggan"
                                                control={control}
                                                rules={{
                                                    required:
                                                        toggleModePembayaran ===
                                                            ModePembayaran.UTANG &&
                                                        !watchIsPelangganBaru
                                                            ? "Kolom wajib diisi"
                                                            : undefined,
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <Autocomplete
                                                        disablePortal
                                                        disableClearable
                                                        loading={
                                                            isLoadingPelanggan
                                                        }
                                                        options={
                                                            pelanggan?.content ??
                                                            []
                                                        }
                                                        getOptionLabel={(
                                                            option,
                                                        ) => {
                                                            return `${option.nama} (${option.noTelp})`;
                                                        }}
                                                        isOptionEqualToValue={(
                                                            option,
                                                            value,
                                                        ) =>
                                                            option.id ===
                                                            value.id
                                                        }
                                                        size="small"
                                                        value={field.value}
                                                        onInputChange={(e) => {
                                                            if (e) {
                                                                const element =
                                                                    e.target as HTMLInputElement;
                                                                setParamsPelanggan(
                                                                    (prev) => ({
                                                                        ...prev,
                                                                        search: element.value,
                                                                    }),
                                                                );
                                                            } else {
                                                                return;
                                                            }
                                                        }}
                                                        onChange={(
                                                            _event,
                                                            newPelanggan: GetPelangganContent | null,
                                                        ) => {
                                                            field.onChange(
                                                                newPelanggan,
                                                            );
                                                        }}
                                                        fullWidth
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                placeholder={
                                                                    toggleModePembayaran ===
                                                                    ModePembayaran.UTANG
                                                                        ? "Pilih pelanggan"
                                                                        : "Pilih pelanggan (opsional)"
                                                                }
                                                                error={
                                                                    !!fieldState?.error
                                                                }
                                                                helperText={
                                                                    fieldState?.error &&
                                                                    "Pilih satu pelanggan"
                                                                }
                                                            />
                                                        )}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: watchIsPelangganBaru
                                                    ? undefined
                                                    : "none",
                                            }}
                                        >
                                            <Typography>Nama:</Typography>
                                            <Controller
                                                name="nama"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsPelangganBaru
                                                            ? "Kolom wajib diisi"
                                                            : undefined,
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <TextField
                                                        placeholder="Contoh: Budi"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        error={
                                                            !!fieldState.error
                                                        }
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : ""
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{
                                                display: watchIsPelangganBaru
                                                    ? undefined
                                                    : "none",
                                            }}
                                        >
                                            <Typography>
                                                No. Telepon:
                                            </Typography>
                                            <Controller
                                                name="noTelepon"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsPelangganBaru
                                                            ? "Kolom wajib diisi"
                                                            : undefined,
                                                    pattern: {
                                                        value: /^8[1-9][0-9]/,
                                                        message:
                                                            "No. Telepon tidak valid, contoh: 831xxxx",
                                                    },
                                                    maxLength: {
                                                        value: 13,
                                                        message:
                                                            "No. Telepon terlalu panjang",
                                                    },
                                                    minLength: {
                                                        value: 9,
                                                        message:
                                                            "No. Telepon terlalu pendek",
                                                    },
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <TextField
                                                        placeholder="8123456789"
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        error={
                                                            !!fieldState.error
                                                        }
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : "No. Telepon tidak boleh sama dengan pelanggan lain"
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    +62
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: watchIsPelangganBaru
                                                    ? undefined
                                                    : "none",
                                            }}
                                        >
                                            <Typography>Alamat:</Typography>
                                            <Controller
                                                name="alamat"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsPelangganBaru
                                                            ? "Kolom wajib diisi"
                                                            : undefined,
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <TextField
                                                        placeholder="Contoh: Jl. Merdeka Raya no. 2"
                                                        fullWidth
                                                        variant="outlined"
                                                        size="small"
                                                        multiline
                                                        rows={2}
                                                        error={
                                                            !!fieldState.error
                                                        }
                                                        helperText={
                                                            fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      .message
                                                                : ""
                                                        }
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            sx={{
                                                display: watchIsPelangganBaru
                                                    ? undefined
                                                    : "none",
                                            }}
                                        >
                                            <Typography>
                                                Tanggal lahir:
                                            </Typography>
                                            <Controller
                                                name="tglLahir"
                                                control={control}
                                                rules={{
                                                    required:
                                                        watchIsPelangganBaru
                                                            ? "Kolom wajib diisi"
                                                            : undefined,
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <>
                                                        <DatePicker
                                                            className="range-filter"
                                                            dateFormat="dd/MM/yyyy"
                                                            disabledKeyboardNavigation
                                                            onFocus={(e) =>
                                                                e.target.blur()
                                                            }
                                                            selected={
                                                                field.value
                                                            }
                                                            onChange={(
                                                                update,
                                                            ) => {
                                                                field.onChange(
                                                                    update,
                                                                );
                                                            }}
                                                            monthsShown={1}
                                                            showYearDropdown
                                                            yearDropdownItemNumber={
                                                                60
                                                            }
                                                            scrollableYearDropdown
                                                            popperPlacement="top-start"
                                                            customInput={
                                                                <Stack
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    direction="row"
                                                                    width="100%"
                                                                    bgcolor="#FFFF"
                                                                    height={40}
                                                                    sx={{
                                                                        border: fieldState.error
                                                                            ? "1px solid #d32f2f"
                                                                            : "1px solid #cbcbcb",
                                                                        borderRadius:
                                                                            "4px",
                                                                        alignItems:
                                                                            "center",
                                                                        padding: 2,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        color={
                                                                            fieldState.error
                                                                                ? "#d32f2f"
                                                                                : undefined
                                                                        }
                                                                    >
                                                                        {field.value
                                                                            ? field.value?.toLocaleString(
                                                                                  "id",
                                                                                  {
                                                                                      year: "numeric",
                                                                                      month: "long",
                                                                                      day: "numeric",
                                                                                  },
                                                                              )
                                                                            : "Pilih tanggal lahir"}
                                                                    </Typography>
                                                                    <ArrowDropDown
                                                                        sx={{
                                                                            marginRight:
                                                                                "-9px",
                                                                        }}
                                                                    />
                                                                </Stack>
                                                            }
                                                        />
                                                        {fieldState.error ? (
                                                            <FormHelperText
                                                                error
                                                                sx={{ ml: 1.5 }}
                                                            >
                                                                Kolom wajib
                                                                diisi
                                                            </FormHelperText>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    {props.kembalian < 0 && (
                                        <Box>
                                            <Typography>Jatuh tempo</Typography>
                                            <Controller
                                                name="jatuhTempo"
                                                control={control}
                                                rules={{
                                                    required:
                                                        "Kolom wajib diisi",
                                                }}
                                                render={({
                                                    field,
                                                    fieldState,
                                                }) => (
                                                    <DatePicker
                                                        className="range-filter"
                                                        dateFormat="dd/MM/yyyy"
                                                        disabledKeyboardNavigation
                                                        onFocus={(e) =>
                                                            e.target.blur()
                                                        }
                                                        selected={field.value}
                                                        onChange={(update) => {
                                                            field.onChange(
                                                                update,
                                                            );
                                                        }}
                                                        // minDate={now}
                                                        monthsShown={1}
                                                        customInput={
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <Stack
                                                                    display="flex"
                                                                    justifyContent="space-between"
                                                                    direction="row"
                                                                    // width="100%"
                                                                    bgcolor="#FFFF"
                                                                    height="40px"
                                                                    sx={{
                                                                        border: !!fieldState.error
                                                                            ? "1px solid red"
                                                                            : "1px solid #cbcbcb",
                                                                        borderRadius:
                                                                            "4px",
                                                                        alignItems:
                                                                            "center",
                                                                        padding: 2,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        color={
                                                                            field.value
                                                                                ? undefined
                                                                                : "#cbcbcb"
                                                                        }
                                                                    >
                                                                        {field.value
                                                                            ? field.value?.toLocaleString(
                                                                                  "id",
                                                                                  {
                                                                                      year: "numeric",
                                                                                      month: "long",
                                                                                      day: "numeric",
                                                                                  },
                                                                              )
                                                                            : "Pilih jatuh tempo"}
                                                                    </Typography>
                                                                    <ArrowDropDown
                                                                        sx={{
                                                                            marginRight:
                                                                                "-9px",
                                                                        }}
                                                                    />
                                                                </Stack>
                                                                <FormHelperText
                                                                    error={
                                                                        !!fieldState.error
                                                                    }
                                                                >
                                                                    {fieldState.error
                                                                        ? fieldState
                                                                              .error
                                                                              ?.message
                                                                        : ""}
                                                                </FormHelperText>
                                                            </FormControl>
                                                        }
                                                    />
                                                )}
                                            />
                                        </Box>
                                    )}
                                    {toggleModePembayaran ===
                                        ModePembayaran.TRANSFER &&
                                    metodePembayaran &&
                                    metodePembayaran?.length > 0 ? (
                                        <Box>
                                            <Autocomplete
                                                loading={
                                                    isLoadingMetodePembayaran
                                                }
                                                options={metodePembayaran ?? []}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Pilih metode"
                                                        variant="outlined"
                                                    />
                                                )}
                                                getOptionLabel={(option) =>
                                                    option.namaMetode
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) =>
                                                    String(option.id) ===
                                                    String(value.id)
                                                }
                                                size="small"
                                                fullWidth
                                                value={selectedMetode}
                                                onChange={(
                                                    _event,
                                                    newKategori: {
                                                        id: number;
                                                        ukmId: {
                                                            id: number | null;
                                                            nama: string | null;
                                                        };
                                                        namaMetode: string;
                                                        charge: {
                                                            mode: string;
                                                            charge: number;
                                                        };
                                                        noTujuan: string | null;
                                                        namaPemegang:
                                                            | string
                                                            | null;
                                                        gambar: string | null;
                                                    } | null,
                                                ) => {
                                                    setSelectedMetode(
                                                        newKategori,
                                                    );
                                                }}
                                                sx={{ marginBottom: 2 }}
                                            />
                                            {selectedMetode && (
                                                <Stack alignItems="center">
                                                    {selectedMetode.gambar && (
                                                        <img
                                                            src={
                                                                selectedMetode.gambar
                                                            }
                                                            alt="qrcode"
                                                            width={250}
                                                            height={250}
                                                        />
                                                    )}
                                                    <Typography>
                                                        a/n{" "}
                                                        {
                                                            selectedMetode.namaPemegang
                                                        }
                                                    </Typography>
                                                    <Typography fontWeight="bold">
                                                        {
                                                            selectedMetode.noTujuan
                                                        }
                                                    </Typography>
                                                    <BlobProvider
                                                        document={
                                                            <PrintStrukPdf
                                                                isIncludeDapur
                                                                dp={Number(
                                                                    props.uangDibayar,
                                                                )}
                                                                namaToko={String(
                                                                    ukmData?.namaToko,
                                                                )}
                                                                alamatToko={String(
                                                                    ukmData?.alamatToko,
                                                                )}
                                                                noInvoice={
                                                                    noInvoice
                                                                }
                                                                gambarToko={
                                                                    ukmData?.gambarTokoBW ===
                                                                    null
                                                                        ? undefined
                                                                        : ukmData?.gambarTokoBW
                                                                }
                                                                telpToko={String(
                                                                    ukmData?.noTelp,
                                                                )}
                                                                telpTokoArr={
                                                                    ukmData?.noTelpArr
                                                                }
                                                                tanggalTransaksi={
                                                                    now
                                                                }
                                                                kasir={
                                                                    props.kasir
                                                                }
                                                                sales={
                                                                    props.sales
                                                                        ?.nama ===
                                                                    undefined
                                                                        ? null
                                                                        : props
                                                                              .sales
                                                                              .nama
                                                                }
                                                                total={
                                                                    props.totalTagihan
                                                                }
                                                                cart={cart}
                                                                diskon={
                                                                    props.diskon
                                                                }
                                                                pajak={
                                                                    props.pajak
                                                                }
                                                                belumLunas={
                                                                    true
                                                                }
                                                                qr={
                                                                    selectedMetode.gambar
                                                                }
                                                                isPro={Number(
                                                                    ukmData?.isPro,
                                                                )}
                                                                namaMetode={
                                                                    selectedMetode &&
                                                                    toggleModePembayaran ===
                                                                        ModePembayaran.TRANSFER
                                                                        ? selectedMetode.namaMetode
                                                                        : props.kembalian <
                                                                          0
                                                                        ? "Piutang"
                                                                        : "Tunai"
                                                                }
                                                                html={
                                                                    findCustomStruk &&
                                                                    findCustomStruk.html !==
                                                                        null
                                                                        ? findCustomStruk?.html
                                                                        : undefined
                                                                }
                                                                keterangan={
                                                                    props.keteranganBeli
                                                                }
                                                                keterangan2={
                                                                    props.keteranganBeli2
                                                                }
                                                                noMetode={
                                                                    selectedMetode.noTujuan
                                                                }
                                                                namaPelanggan={
                                                                    watchIsPelangganBaru
                                                                        ? watch(
                                                                              "nama",
                                                                          )
                                                                        : watchPelanggan?.nama
                                                                }
                                                                jatuhTempo={
                                                                    props.kembalian <
                                                                    0
                                                                        ? moment(
                                                                              watchJatuhTempo,
                                                                          ).format(
                                                                              "DD MMM YYYY",
                                                                          )
                                                                        : null
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                        }) => {
                                                            return (
                                                                <LoadingButton
                                                                    variant="outlined"
                                                                    loading={
                                                                        loading
                                                                    }
                                                                    loadingPosition="center"
                                                                    startIcon={
                                                                        <Print />
                                                                    }
                                                                    href={
                                                                        url ??
                                                                        ""
                                                                    }
                                                                    // onClick={() => {
                                                                    //     pDapur &&
                                                                    //         pDapur.current?.click();
                                                                    // }}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    sx={{
                                                                        marginTop: 2,
                                                                    }}
                                                                >
                                                                    Cetak
                                                                    Tagihan
                                                                </LoadingButton>
                                                            );
                                                        }}
                                                    </BlobProvider>
                                                </Stack>
                                            )}
                                        </Box>
                                    ) : (
                                        toggleModePembayaran ===
                                            ModePembayaran.TRANSFER &&
                                        metodePembayaran?.length === 0 && (
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                justifyContent="flex-end"
                                                height="50%"
                                                paddingX={
                                                    isPhoneScreen
                                                        ? undefined
                                                        : 2
                                                }
                                            >
                                                <Typography textAlign="center">
                                                    Tambahkan Bank Transfer,
                                                    e-Money, QRIS, dan lain-lain
                                                    sebagai metode alternatif
                                                    transaksi UKM Anda.
                                                </Typography>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    marginTop={4}
                                                >
                                                    <Typography
                                                        variant="body2"
                                                        textAlign="center"
                                                    >
                                                        Metode lain dapat diatur
                                                        di
                                                        cikmeme-juragan.noretest.com
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    marginTop={2}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        href={
                                                            process.env
                                                                .NODE_ENV ===
                                                            "production"
                                                                ? `${JURAGAN_URL}/ukm/metode-pembayaran`
                                                                : `http://localhost:3000/ukm/metode-pembayaran`
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Ke Juragan
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    )}
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            borderLeft="1px solid #d4d4d4"
                            borderRadius="10px"
                        >
                            {/* <Divider flexItem /> */}
                            <Box
                                display="grid"
                                gridTemplateRows="auto 1fr auto"
                                height={
                                    fullScreen
                                        ? undefined
                                        : isTabletScreen
                                        ? "calc(100vh - 72px)"
                                        : "calc(90vh - 72px)"
                                }
                            >
                                <Stack
                                    direction="row"
                                    paddingLeft={3}
                                    paddingRight={4}
                                    paddingY={2}
                                    borderBottom="1px solid #d4d4d4"
                                    borderTop="1px solid #d4d4d4"
                                    borderRadius="10px 0 0 0"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                Tanggal
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="black"
                                            >
                                                {now}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                Nama Kasir
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="black"
                                            >
                                                {user?.namaLengkap}
                                            </Typography>
                                        </Grid>
                                        {!!props.sales && (
                                            <Grid item>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="#A1A1AA"
                                                >
                                                    Nama Sales
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="black"
                                                >
                                                    {props.sales.nama}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                                <DialogContent
                                    sx={{
                                        paddingBottom: 2,
                                    }}
                                >
                                    <Stack direction="column" spacing={1}>
                                        {cart ? (
                                            cart.map((rows, idx) => (
                                                <Box key={String(idx)}>
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="space-between"
                                                    >
                                                        <Box width="50%">
                                                            <Box>
                                                                <Tooltip
                                                                    title={
                                                                        rows.namaBarang
                                                                    }
                                                                >
                                                                    <Typography
                                                                        variant={
                                                                            isTabletScreen
                                                                                ? "body2"
                                                                                : "subtitle1"
                                                                        }
                                                                        fontWeight={
                                                                            600
                                                                        }
                                                                        color="black"
                                                                    >
                                                                        {truncate(
                                                                            rows.namaBarang,
                                                                            20,
                                                                        )}
                                                                    </Typography>
                                                                </Tooltip>
                                                                <Typography>
                                                                    Rp.{" "}
                                                                    {toRibuan(
                                                                        rows.harga,
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box display="flex">
                                                            <Typography
                                                                variant={
                                                                    isTabletScreen
                                                                        ? "body2"
                                                                        : "subtitle1"
                                                                }
                                                                fontWeight={600}
                                                                color="black"
                                                            >
                                                                {rows.qty}x
                                                            </Typography>
                                                        </Box>
                                                        <Stack
                                                            direction="row"
                                                            width="30%"
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                        >
                                                            <Typography
                                                                display="flex"
                                                                variant={
                                                                    isTabletScreen
                                                                        ? "body2"
                                                                        : "subtitle1"
                                                                }
                                                                fontWeight={600}
                                                                color="black"
                                                                textAlign="end"
                                                            >
                                                                Rp.{" "}
                                                                {toRibuan(
                                                                    rows.harga *
                                                                        rows.qty,
                                                                )}
                                                            </Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Box>
                                            ))
                                        ) : (
                                            <Typography variant="h6">
                                                Barang tidak ditemukan
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Box
                                        marginY={2}
                                        padding={2}
                                        border={1}
                                        borderColor="#d4d4d4"
                                        borderRadius={2}
                                    >
                                        <Typography
                                            marginBottom={1}
                                            variant={
                                                isTabletScreen
                                                    ? "body2"
                                                    : "subtitle1"
                                            }
                                            color="#000000"
                                            fontWeight="bold"
                                        >
                                            Ringkasan Pembayaran
                                        </Typography>
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Subtotal Harga
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(totalHarga)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={5}
                                                lg={5}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Diskon{" "}
                                                    {props.persentaseDiskon &&
                                                    props.persentaseDiskon > 0
                                                        ? `(${props.persentaseDiskon}%)`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.diskon ?? 0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={4}
                                                md={5}
                                                lg={5}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Pajak{" "}
                                                    {props.persentasePajak &&
                                                    props.persentasePajak > 0
                                                        ? `(${props.persentasePajak}%)`
                                                        : ""}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.pajak ?? 0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Total Tagihan
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#E8F6ED"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#45A779"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#45A779"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.totalTagihan,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    {props.kembalian < 0
                                                        ? "Dp"
                                                        : "Uang yang Dibayar"}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FFF5EC"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#FB923C"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.uangDibayar ??
                                                                0,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                            justifyContent="space-between"
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    {props.kembalian < 0
                                                        ? "Piutang"
                                                        : "Kembalian"}
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    bgcolor="#FCEAEA"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#DC2626"
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        color="#DC2626"
                                                        fontWeight="bold"
                                                    >
                                                        {toRibuan(
                                                            props.kembalian,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        {props.keteranganBeli && (
                                            <>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    marginTop={1}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            color="#404040"
                                                        >
                                                            Keterangan
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled
                                                            multiline
                                                            rows={2}
                                                            fullWidth
                                                            value={
                                                                props.keteranganBeli
                                                            }
                                                            sx={{
                                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                                    {
                                                                        color: "rgba(0, 0, 0, 0.87)",
                                                                        WebkitTextFillColor:
                                                                            "rgba(0, 0, 0, 0.87)",
                                                                    },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    alignItems="center"
                                                    marginTop={1}
                                                >
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            color="#404040"
                                                        >
                                                            Keterangan 2
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            disabled
                                                            multiline
                                                            rows={2}
                                                            fullWidth
                                                            value={
                                                                props.keteranganBeli2
                                                            }
                                                            sx={{
                                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                                    {
                                                                        color: "rgba(0, 0, 0, 0.87)",
                                                                        WebkitTextFillColor:
                                                                            "rgba(0, 0, 0, 0.87)",
                                                                    },
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )}
                                    </Box>
                                </DialogContent>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    display={fullScreen ? "none" : undefined}
                                >
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box
                                            paddingTop={3}
                                            paddingLeft={3}
                                            display="flex"
                                            justifyContent="flex-start"
                                            borderTop={1}
                                            borderColor="#d4d4d4"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={printStruk}
                                                        size={
                                                            isTabletScreen
                                                                ? "small"
                                                                : undefined
                                                        }
                                                        onChange={(
                                                            _,
                                                            checked,
                                                        ) => {
                                                            handlePrintStruk(
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Print struk"
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: isTabletScreen
                                                            ? "12px"
                                                            : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    height: "fit-content",
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            paddingLeft={3}
                                            display="flex"
                                            // justifyContent="flex-start"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={kirimEmail}
                                                        size={
                                                            isTabletScreen
                                                                ? "small"
                                                                : undefined
                                                        }
                                                        onChange={(
                                                            _,
                                                            checked,
                                                        ) => {
                                                            handleKirimEmail(
                                                                checked,
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Kirim struk"
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: isTabletScreen
                                                            ? "12px"
                                                            : undefined,
                                                    },
                                                }}
                                                sx={{
                                                    height: "fit-content",
                                                }}
                                            />
                                        </Box>
                                        {kirimEmail && (
                                            <Box paddingX={3}>
                                                <Grid container>
                                                    <Grid item xs={12} sm={6}>
                                                        <Stack
                                                            display="flex"
                                                            direction="row"
                                                            spacing={1}
                                                            sx={{
                                                                marginRight: 2,
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={
                                                                    user &&
                                                                    !user.akses.transaksi.includes(
                                                                        12,
                                                                    )
                                                                }
                                                                size={
                                                                    isTabletScreen
                                                                        ? "small"
                                                                        : "medium"
                                                                }
                                                                fullWidth
                                                                variant={
                                                                    toggleKirimStruk ===
                                                                    "whatsapp"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    pilihWhatsapp()
                                                                }
                                                            >
                                                                Whatsapp
                                                            </Button>
                                                            <Button
                                                                disabled={
                                                                    user &&
                                                                    !user.akses.transaksi.includes(
                                                                        12,
                                                                    )
                                                                }
                                                                size={
                                                                    isTabletScreen
                                                                        ? "small"
                                                                        : "medium"
                                                                }
                                                                fullWidth
                                                                variant={
                                                                    toggleKirimStruk ===
                                                                    "email"
                                                                        ? "contained"
                                                                        : "outlined"
                                                                }
                                                                onClick={() =>
                                                                    pilihEmail()
                                                                }
                                                            >
                                                                Email
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            value={email}
                                                            size="small"
                                                            type="email"
                                                            fullWidth
                                                            sx={{
                                                                maxWidth: 300,
                                                            }}
                                                            placeholder={
                                                                toggleKirimStruk ===
                                                                "email"
                                                                    ? "Contoh: budi93@gmail.com"
                                                                    : "8316352725"
                                                            }
                                                            InputProps={{
                                                                startAdornment:
                                                                    toggleKirimStruk ===
                                                                        "whatsapp" && (
                                                                        <InputAdornment position="start">
                                                                            +62
                                                                        </InputAdornment>
                                                                    ),
                                                            }}
                                                            onChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                            ) =>
                                                                setEmail(
                                                                    event.target
                                                                        .value,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )}
                                        <Box
                                            marginTop={isTabletScreen ? 1 : 2}
                                            marginLeft={3}
                                            marginRight={3}
                                            marginBottom={
                                                isTabletScreen ? 2 : 4
                                            }
                                        >
                                            <Stack
                                                direction="column"
                                                display="flex"
                                                justifyContent="space-between"
                                                marginBottom={1}
                                                spacing={1}
                                            >
                                                <Stack
                                                    direction="row"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Total
                                                    </Typography>
                                                    <Box
                                                        bgcolor="#E8F6ED"
                                                        borderRadius={1}
                                                        paddingX={2}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                            color="#45A779"
                                                        >
                                                            Rp.{" "}
                                                            {toRibuan(
                                                                props.totalTagihan,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    direction="row"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        {props.kembalian < 0
                                                            ? "Piutang"
                                                            : "Kembalian"}
                                                    </Typography>
                                                    <Box
                                                        justifyContent="space-between"
                                                        bgcolor="#FCEAEA"
                                                        paddingX={2}
                                                        borderRadius={1}
                                                    >
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight="bold"
                                                            color="#DC2626"
                                                        >
                                                            Rp.{" "}
                                                            {toRibuan(
                                                                props.kembalian,
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    flexDirection={"row"}
                                                    gap={1}
                                                >
                                                    <BlobProvider
                                                        document={
                                                            <PrintDapur
                                                                namaToko={String(
                                                                    ukmData?.namaToko,
                                                                )}
                                                                alamatToko={String(
                                                                    ukmData?.alamatToko,
                                                                )}
                                                                gambarToko={
                                                                    ukmData?.gambarTokoBW ===
                                                                    null
                                                                        ? undefined
                                                                        : ukmData?.gambarTokoBW
                                                                }
                                                                telpToko={String(
                                                                    ukmData?.noTelp,
                                                                )}
                                                                tanggalTransaksi={
                                                                    now
                                                                }
                                                                kasir={
                                                                    props.kasir
                                                                }
                                                                sales={
                                                                    props.sales
                                                                        ?.nama ===
                                                                    undefined
                                                                        ? null
                                                                        : props
                                                                              .sales
                                                                              .nama
                                                                }
                                                                total={
                                                                    props.totalTagihan
                                                                }
                                                                cart={cart}
                                                                diskon={
                                                                    props.diskon
                                                                }
                                                                pajak={
                                                                    props.pajak
                                                                }
                                                                tunai={Number(
                                                                    props.uangDibayar,
                                                                )}
                                                                kembalian={
                                                                    props.kembalian
                                                                }
                                                                isPro={Number(
                                                                    ukmData?.isPro,
                                                                )}
                                                                namaMetode={
                                                                    selectedMetode &&
                                                                    toggleModePembayaran ===
                                                                        ModePembayaran.TRANSFER
                                                                        ? selectedMetode.namaMetode
                                                                        : props.kembalian <
                                                                          0
                                                                        ? "Piutang"
                                                                        : "Tunai"
                                                                }
                                                                html={
                                                                    findCustomStruk &&
                                                                    findCustomStruk.html !==
                                                                        null
                                                                        ? findCustomStruk?.html
                                                                        : undefined
                                                                }
                                                                keterangan={
                                                                    props.keteranganBeli
                                                                }
                                                                noMetode={
                                                                    selectedMetode?.noTujuan ??
                                                                    null
                                                                }
                                                                namaPelanggan={
                                                                    watchIsPelangganBaru
                                                                        ? watch(
                                                                              "nama",
                                                                          )
                                                                        : watchPelanggan?.nama
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {({
                                                            blob,
                                                            url,
                                                            loading,
                                                            error,
                                                        }) => {
                                                            return (
                                                                <LoadingButton
                                                                    style={{
                                                                        display:
                                                                            "none",
                                                                    }}
                                                                    ref={pDapur}
                                                                    loading={
                                                                        loading ||
                                                                        isButtonLoading
                                                                    }
                                                                    loadingPosition="center"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    href={
                                                                        url ??
                                                                        ""
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Print Dapur
                                                                </LoadingButton>
                                                            );
                                                        }}
                                                    </BlobProvider>
                                                    {printStruk ? (
                                                        <BlobProvider
                                                            document={
                                                                <PrintStrukPdf
                                                                    isIncludeDapur
                                                                    dp={Number(
                                                                        props.uangDibayar,
                                                                    )}
                                                                    namaToko={String(
                                                                        ukmData?.namaToko,
                                                                    )}
                                                                    alamatToko={String(
                                                                        ukmData?.alamatToko,
                                                                    )}
                                                                    noInvoice={
                                                                        noInvoice
                                                                    }
                                                                    gambarToko={
                                                                        ukmData?.gambarTokoBW ===
                                                                        null
                                                                            ? undefined
                                                                            : ukmData?.gambarTokoBW
                                                                    }
                                                                    telpToko={String(
                                                                        ukmData?.noTelp,
                                                                    )}
                                                                    telpTokoArr={
                                                                        ukmData?.noTelpArr
                                                                    }
                                                                    tanggalTransaksi={
                                                                        now
                                                                    }
                                                                    kasir={
                                                                        props.kasir
                                                                    }
                                                                    sales={
                                                                        props
                                                                            .sales
                                                                            ?.nama ===
                                                                        undefined
                                                                            ? null
                                                                            : props
                                                                                  .sales
                                                                                  .nama
                                                                    }
                                                                    total={
                                                                        props.totalTagihan
                                                                    }
                                                                    cart={cart}
                                                                    diskon={
                                                                        props.diskon
                                                                    }
                                                                    pajak={
                                                                        props.pajak
                                                                    }
                                                                    tunai={Number(
                                                                        props.uangDibayar,
                                                                    )}
                                                                    kembalian={
                                                                        props.kembalian
                                                                    }
                                                                    isPro={Number(
                                                                        ukmData?.isPro,
                                                                    )}
                                                                    namaMetode={
                                                                        selectedMetode &&
                                                                        toggleModePembayaran ===
                                                                            ModePembayaran.TRANSFER
                                                                            ? selectedMetode.namaMetode
                                                                            : props.kembalian <
                                                                              0
                                                                            ? "Piutang"
                                                                            : "Tunai"
                                                                    }
                                                                    html={
                                                                        findCustomStruk &&
                                                                        findCustomStruk.html !==
                                                                            null
                                                                            ? findCustomStruk?.html
                                                                            : undefined
                                                                    }
                                                                    keterangan={
                                                                        props.keteranganBeli
                                                                    }
                                                                    keterangan2={
                                                                        props.keteranganBeli2
                                                                    }
                                                                    noMetode={
                                                                        selectedMetode?.noTujuan ??
                                                                        null
                                                                    }
                                                                    namaPelanggan={
                                                                        watchIsPelangganBaru
                                                                            ? watch(
                                                                                  "nama",
                                                                              )
                                                                            : watchPelanggan?.nama
                                                                    }
                                                                    jatuhTempo={
                                                                        props.kembalian <
                                                                        0
                                                                            ? moment(
                                                                                  watchJatuhTempo,
                                                                              ).format(
                                                                                  "DD MMM YYYY",
                                                                              )
                                                                            : null
                                                                    }
                                                                />
                                                            }
                                                        >
                                                            {({
                                                                blob,
                                                                url,
                                                                loading,
                                                                error,
                                                            }) => {
                                                                return (
                                                                    <LoadingButton
                                                                        disabled={
                                                                            toggleModePembayaran ===
                                                                                ModePembayaran.TRANSFER &&
                                                                            selectedMetode ===
                                                                                null
                                                                        }
                                                                        loading={
                                                                            loading ||
                                                                            isButtonLoading
                                                                        }
                                                                        loadingPosition="center"
                                                                        variant="contained"
                                                                        fullWidth
                                                                        size={
                                                                            isTabletScreen
                                                                                ? "medium"
                                                                                : "large"
                                                                        }
                                                                        // href={
                                                                        //     url ??
                                                                        //     ""
                                                                        // }
                                                                        // target="_blank"
                                                                        // rel="noreferrer"
                                                                        onClick={() => {
                                                                            if (
                                                                                blob
                                                                            ) {
                                                                                handleSubmit(
                                                                                    onSubmit,
                                                                                )();
                                                                                const blobFile =
                                                                                    new File(
                                                                                        [
                                                                                            blob,
                                                                                        ],
                                                                                        `Invoice_${ukmData?.namaToko}_${now}.pdf`,
                                                                                        {
                                                                                            type: "application/pdf",
                                                                                        },
                                                                                    );
                                                                                const invoiceUrl =
                                                                                    window.URL.createObjectURL(
                                                                                        blobFile,
                                                                                    );
                                                                                console.log(
                                                                                    invoiceUrl,
                                                                                );
                                                                                window.open(
                                                                                    invoiceUrl,
                                                                                );
                                                                                // printJS(
                                                                                //     {
                                                                                //         printable:
                                                                                //             URL.createObjectURL(
                                                                                //                 blob,
                                                                                //             ),
                                                                                //         type: "pdf",
                                                                                //         showModal:
                                                                                //             true,
                                                                                //         modalMessage:
                                                                                //             "Mohon tunggu",
                                                                                //     },
                                                                                // );
                                                                            }
                                                                        }}
                                                                    >
                                                                        Bayar
                                                                    </LoadingButton>
                                                                );
                                                            }}
                                                        </BlobProvider>
                                                    ) : (
                                                        <LoadingButton
                                                            disabled={
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER &&
                                                                selectedMetode ===
                                                                    null
                                                            }
                                                            loading={
                                                                isButtonLoading
                                                            }
                                                            loadingPosition="center"
                                                            variant="contained"
                                                            fullWidth
                                                            size={
                                                                isTabletScreen
                                                                    ? "medium"
                                                                    : "large"
                                                            }
                                                            onClick={() => {
                                                                handleSubmit(
                                                                    onSubmit,
                                                                )();
                                                            }}
                                                        >
                                                            Bayar
                                                        </LoadingButton>
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Stack>
                    <Grid
                        container
                        justifyContent="flex-end"
                        display={fullScreen ? undefined : "none"}
                        position="fixed"
                        bottom={0}
                        bgcolor="#fff"
                        zIndex={1}
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                                paddingTop={3}
                                paddingLeft={3}
                                display="flex"
                                justifyContent="flex-start"
                                borderTop={1}
                                borderColor="#d4d4d4"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={printStruk}
                                            onChange={(_, checked) => {
                                                handlePrintStruk(checked);
                                            }}
                                        />
                                    }
                                    label="Print struk"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                            </Box>
                            <Box
                                paddingLeft={3}
                                display="flex"
                                // justifyContent="flex-start"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={kirimEmail}
                                            onChange={(_, checked) => {
                                                handleKirimEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Kirim struk"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                            </Box>
                            {kirimEmail && (
                                <Box paddingX={3}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                display="flex"
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    marginRight: 2,
                                                }}
                                            >
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size="medium"
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "whatsapp"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() =>
                                                        pilihWhatsapp()
                                                    }
                                                >
                                                    Whatsapp
                                                </Button>
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size="medium"
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "email"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() => pilihEmail()}
                                                >
                                                    Email
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={email}
                                                size="small"
                                                type="email"
                                                fullWidth
                                                sx={{ maxWidth: 300 }}
                                                placeholder={
                                                    toggleKirimStruk === "email"
                                                        ? "Contoh: budi93@gmail.com"
                                                        : "8316352725"
                                                }
                                                InputProps={{
                                                    startAdornment:
                                                        toggleKirimStruk ===
                                                            "whatsapp" && (
                                                            <InputAdornment position="start">
                                                                +62
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    setEmail(event.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            <Box
                                marginTop={2}
                                marginLeft={3}
                                marginRight={3}
                                marginBottom={4}
                            >
                                <Stack
                                    direction="column"
                                    display="flex"
                                    justifyContent="space-between"
                                    marginBottom={1}
                                    spacing={1}
                                >
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            Total
                                        </Typography>
                                        <Box
                                            bgcolor="#E8F6ED"
                                            borderRadius={1}
                                            paddingX={2}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                color="#45A779"
                                            >
                                                Rp.{" "}
                                                {toRibuan(props.totalTagihan)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                        >
                                            {props.kembalian < 0
                                                ? "Piutang"
                                                : "Kembalian"}
                                        </Typography>
                                        <Box
                                            justifyContent="space-between"
                                            bgcolor="#FCEAEA"
                                            paddingX={2}
                                            borderRadius={1}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight="bold"
                                                color="#DC2626"
                                            >
                                                Rp. {toRibuan(props.kembalian)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Stack>
                                {printStruk ? (
                                    <BlobProvider
                                        document={
                                            <PrintStrukPdf
                                                isIncludeDapur
                                                namaToko={String(
                                                    ukmData?.namaToko,
                                                )}
                                                noInvoice={noInvoice}
                                                alamatToko={String(
                                                    ukmData?.alamatToko,
                                                )}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW ===
                                                    null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                telpToko={String(
                                                    ukmData?.noTelp,
                                                )}
                                                telpTokoArr={ukmData?.noTelpArr}
                                                tanggalTransaksi={now}
                                                kasir={props.kasir}
                                                sales={
                                                    props.sales?.nama ===
                                                    undefined
                                                        ? null
                                                        : props.sales.nama
                                                }
                                                total={props.totalTagihan}
                                                cart={cart}
                                                diskon={props.diskon}
                                                pajak={props.pajak}
                                                tunai={Number(
                                                    props.uangDibayar,
                                                )}
                                                dp={Number(props.uangDibayar)}
                                                kembalian={props.kembalian}
                                                isPro={Number(ukmData?.isPro)}
                                                namaMetode={
                                                    selectedMetode
                                                        ? selectedMetode.namaMetode
                                                        : props.kembalian < 0
                                                        ? "Piutang"
                                                        : "Tunai"
                                                }
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !==
                                                        null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={
                                                    props.keteranganBeli
                                                }
                                                keterangan2={
                                                    props.keteranganBeli2
                                                }
                                                noMetode={
                                                    selectedMetode?.noTujuan ??
                                                    null
                                                }
                                                namaPelanggan={
                                                    watchIsPelangganBaru
                                                        ? watch("nama")
                                                        : watchPelanggan?.nama
                                                }
                                                jatuhTempo={
                                                    props.kembalian < 0
                                                        ? moment(
                                                              watchJatuhTempo,
                                                          ).format(
                                                              "DD MMM YYYY",
                                                          )
                                                        : null
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    disabled={
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER &&
                                                        selectedMetode === null
                                                    }
                                                    loading={
                                                        loading ||
                                                        isButtonLoading
                                                    }
                                                    loadingPosition="center"
                                                    variant="contained"
                                                    fullWidth
                                                    size="large"
                                                    // href={
                                                    //     url ??
                                                    //     ""
                                                    // }
                                                    // target="_blank"
                                                    // rel="noreferrer"
                                                    onClick={() => {
                                                        if (blob) {
                                                            handleSubmit(
                                                                onSubmit,
                                                            )();
                                                            const blobFile =
                                                                new File(
                                                                    [blob],
                                                                    `Invoice_${ukmData?.namaToko}_${now}.pdf`,
                                                                    {
                                                                        type: "application/pdf",
                                                                    },
                                                                );
                                                            const invoiceUrl =
                                                                window.URL.createObjectURL(
                                                                    blobFile,
                                                                );
                                                            console.log(
                                                                invoiceUrl,
                                                            );
                                                            window.open(
                                                                invoiceUrl,
                                                            );
                                                            // printJS({
                                                            //     printable:
                                                            //         URL.createObjectURL(
                                                            //             blob,
                                                            //         ),
                                                            //     type: "pdf",
                                                            //     showModal: true,
                                                            //     modalMessage:
                                                            //         "Mohon tunggu",
                                                            // });
                                                        }
                                                    }}
                                                >
                                                    Bayar
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ) : (
                                    <LoadingButton
                                        disabled={
                                            toggleModePembayaran ===
                                                ModePembayaran.TRANSFER &&
                                            selectedMetode === null
                                        }
                                        loading={isButtonLoading}
                                        loadingPosition="center"
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                        onClick={() => {
                                            handleSubmit(onSubmit)();
                                        }}
                                    >
                                        Bayar
                                    </LoadingButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {ukmData && (
                <ModalAlertPro
                    isOpenModalAlertPro={isOpenModalAlertPro}
                    closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                    dataUkm={{ id: ukmData?.id, nama: ukmData?.namaToko }}
                />
            )}
        </Box>
    );
};

export default ModalKonfirmasiBayar;
