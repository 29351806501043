import React, { useEffect, useMemo, useState } from "react";
import { GetPelangganContent } from "../../constants/types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { useTransaksi } from "../../context/transaksiContext";
import { useAuth } from "../../context/authContext";
import handleErrorResponse from "../../utils/handleErrorResponse";
import InputAdornment from "@mui/material/InputAdornment";

registerLocale("id", id);
setDefaultLocale("id");

interface IValues {
    id?: number;
    ukm: {
        id: string | number;
        nama: string;
    };
    nama: string;
    alamat: string;
    noTelepon: string;
    tanggal?: Date | null;
}

interface IModalPelangganProps {
    isOpenModalPelanggan: boolean;
    closeModalPelanggan: () => void;
    dataPelanggan?: GetPelangganContent;
}

const schema = yup.object({
    nama: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    alamat: yup
        .string()
        .required("Kolom wajib diisi")
        .max(100, "Alamat terlalu panjang"),
    noTelepon: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
});

const ModalPelanggan = ({
    isOpenModalPelanggan,
    closeModalPelanggan,
    dataPelanggan,
}: IModalPelangganProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { setFetchingItems } = useTransaksi();
    const { ukmIdUser } = useAuth();

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const initialValues = useMemo(
        () => ({
            ukm: undefined,
            nama: "",
            alamat: "",
            noTelepon: "",
            tanggal: undefined,
        }),
        [],
    );

    const { handleSubmit, control, reset } = useForm<IValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IValues) => {
        console.log(values);
        setIsButtonLoading(true);
        const formattedNoTelp = "62" + values.noTelepon;
        if (dataPelanggan?.nama) {
            try {
                const formData = new FormData();
                formData.append("ukmId", ukmIdUser ? String(ukmIdUser[0]) : "");
                formData.append("nama", values.nama);
                formData.append("alamat", values.alamat);
                formData.append("noTelp", formattedNoTelp);
                formData.append(
                    "tglLahir",
                    moment(values.tanggal).format("YYYY-MM-DD"),
                );
                formData.append("_method", "PUT");

                const { data } = await axios.post(
                    `/api/pelanggan/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: "Pelanggan telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalPelanggan();
                    reset(initialValues);
                    setFetchingItems();
                }
                setIsButtonLoading(false);
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorResponse(error);
                console.error(error);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", ukmIdUser ? String(ukmIdUser[0]) : "");
                formData.append("nama", values.nama);
                formData.append("alamat", values.alamat);
                formData.append("noTelp", formattedNoTelp);
                formData.append(
                    "tglLahir",
                    moment(values.tanggal).format("YYYY-MM-DD"),
                );

                const { data } = await axios.post(`/api/pelanggan`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                if (data.code === 200) {
                    Swal.fire({
                        title: values.nama + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalPelanggan();
                    reset(initialValues);
                    setFetchingItems();
                }
                setIsButtonLoading(false);
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorResponse(error);
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (isOpenModalPelanggan) {
            if (dataPelanggan) {
                reset({
                    ...dataPelanggan,
                    noTelepon: dataPelanggan.noTelp,
                    // tanggal: moment(dataPelanggan.tglLahir).toDate(),
                    tanggal: dataPelanggan.tglLahir ? moment(dataPelanggan.tglLahir).toDate() : null,
                });
            } else {
                reset(initialValues);
            }
        }
    }, [reset, initialValues, dataPelanggan, isOpenModalPelanggan]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalPelanggan}
            onClose={() => closeModalPelanggan()}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalPelanggan}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        {dataPelanggan?.nama ? "Ubah Pelanggan" : "Tambah Pelanggan"}
                    </Typography>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ padding: isPhoneScreen ? undefined : 6 }}>
                <Stack spacing={6}>
                    <Grid
                        container
                        alignItems="center"
                        rowSpacing={2}
                        columnSpacing={2}
                    >
                        <Grid item xs={12}>
                            <Typography>Nama:</Typography>
                            <Controller
                                name="nama"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        placeholder="Nama"
                                        fullWidth
                                        variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Tanggal lahir:</Typography>
                            <Controller
                                name="tanggal"
                                control={control}
                                render={({ field }) => (
                                    <DatePicker
                                        className="range-filter"
                                        dateFormat="dd/MM/yyyy"
                                        disabledKeyboardNavigation
                                        onFocus={(e) => e.target.blur()}
                                        // selected={field.value}
                                        selected={field.value ? field.value : null}
                                        onChange={(update) => {
                                            field.onChange(update);
                                        }}
                                        monthsShown={1}
                                        showYearDropdown
                                        yearDropdownItemNumber={60}
                                        scrollableYearDropdown
                                        customInput={
                                            <Stack
                                                display="flex"
                                                justifyContent="space-between"
                                                direction="row"
                                                width="100%"
                                                bgcolor="#FFFF"
                                                height={56}
                                                sx={{
                                                    border: "1px solid #cbcbcb",
                                                    borderRadius: "4px",
                                                    alignItems: "center",
                                                    padding: 2,
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <Typography>
                                                    {field.value
                                                        ? field.value?.toLocaleString(
                                                              "id",
                                                              {
                                                                  year: "numeric",
                                                                  month: "long",
                                                                  day: "numeric",
                                                              },
                                                          )
                                                        : "Pilih tanggal lahir"}
                                                </Typography>
                                                <ArrowDropDown
                                                    sx={{
                                                        marginRight: "-9px",
                                                    }}
                                                />
                                            </Stack>
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Alamat:</Typography>
                            <Controller
                                name="alamat"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        placeholder="Alamat"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>No. Telepon:</Typography>
                            <Controller
                                name="noTelepon"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <TextField
                                        placeholder="8123456789"
                                        variant="outlined"
                                        fullWidth
                                        error={!!fieldState.error}
                                        helperText={
                                            fieldState.error
                                                ? fieldState.error.message
                                                : "No. Telepon tidak boleh sama dengan pelanggan lain"
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    +62
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalPelanggan;
