import React, { useState } from "react";
import { GetPelangganContent } from "../../constants/types";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { useTransaksi } from "../../context/transaksiContext";
import handleErrorResponse from "../../utils/handleErrorResponse";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";

interface IModalDeletePelangganProps {
    open: boolean;
    onClose: () => void;
    dataPelanggan: GetPelangganContent;
}

const ModalDeletePelanggan = ({
    open,
    onClose,
    dataPelanggan,
}: IModalDeletePelangganProps) => {
    const { setFetchingItems } = useTransaksi();
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleDelete = async () => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.delete(
                `/api/pelanggan/${dataPelanggan.id}`,
            );
            if (data.code === 200) {
                Swal.fire({
                    title:
                        "Pelanggan " +
                        dataPelanggan.nama +
                        " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
            }
            setIsButtonLoading(false);
            onClose();
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={onClose}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Pelanggan
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pelanggan {dataPelanggan.nama} akan dihapus secara permanen.
                    Yakin ingin menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => handleDelete()}
                    color="error"
                >
                    Hapus
                </LoadingButton>
                <Button variant="contained" onClick={onClose} color="error">
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeletePelanggan;
