import { useQuery } from "react-query";
import {
    ParamsGetBroadcastLog,
    GetBroadcastLogResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetBroadcastLog) => {
    const apiGetBroadcastLog = queryString.stringifyUrl({
        url: "api/broadcast-log",
        query: {
            size: params.size,
            page: params.page,
        },
    });
    const { data } = await axios.get<GetBroadcastLogResponse>(
        apiGetBroadcastLog,
    );
    return data.data;
};

export default function useBroadcastLog(params: ParamsGetBroadcastLog) {
    return useQuery<GetBroadcastLogResponse["data"]>(
        ["broadcast-log", params],
        () => handleRequest(params),
    );
}
