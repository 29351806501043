import { useQuery } from "react-query";
import { GetBroadcastSettingResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async () => {
    const apiGetBroadcastSetting = qs.stringifyUrl({
        url: "api/broadcast-setting",
    });
    const { data } = await axios.get<GetBroadcastSettingResponse>(
        apiGetBroadcastSetting,
    );
    return data.data;
};

export default function useBroadcastSetting() {
    return useQuery<GetBroadcastSettingResponse["data"]>(
        ["broadcast-setting"],
        () => handleRequest(),
    );
}
